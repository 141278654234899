.paging {
	margin: 15px;
	.pull-right {
		line-height: 36px;
	}
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 0;

	> li {
		display: inline;
		> a, > span {
			position: relative;
			float: left;
			margin-right: 8px;
			line-height: 32px;
			width: 40px;
			height: 34px;
			text-decoration: none;
			text-align: center;
			color: $tc;
			font-size: 24px;
			font-weight: 900;
		}
		&:first-child {
			> a, > span {
				margin-left: 0;
			}
		}
	}

	> li > a, > li > span {
		&:hover, &:focus {
			color: $bk;
		}
	}

	> .active > a, > .active > span {
		&, &:hover, &:focus {
			color: $bk;
			cursor: default;
		}
	}
}