.owl-carousel {
	display: block !important;
}

.s24 {
	position: absolute;
	right: 0;
	top: 20px;
}

.product-block .s24 {
	width: 27% !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		@include flex();
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		@include flex();
		flex-wrap: wrap;
		li {
			padding: 5px 0;
			margin-right: 10px;
			margin-left: 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				@include flex();
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				img {
					max-width: calc(100% - 45px);
				}
				span {
					padding: 10px 0;
					display: inline-block;
					text-align: center;
					font-weight: 700;
					font-size: 18px;
					width: 100%;
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(20% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.information-contact {
	.lcol, .rcol {
		width: 50%;
	}
	#all_info {
		h5 {
			font-size: 18px;
			font-weight: 600;
		}
	}
	.contacts_map {
		display: flex;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
			h1 {
				text-align: left;
				margin-top: 20px;
			}
			ul {
				padding: 0;
				list-style: none;
			}
			li {
				padding: 20px 0 20px 65px;
				background: top 30px left no-repeat transparent;
				background-size: 40px auto;
				position: relative;
				span {
					font-size: 18px;
					line-height: 20px;
					display: block;
				}
				&:after {
					content: '';
					position: absolute;
					background: $wh;
					height: 1px;
					width: 75%;
					top: 3px;
				}
				&:first-child {
					&:after {
						display: none;
					}
				}
			}
			@for $i from 6 through 10 {
				li:nth-child(#{$i - 5}) {
					background-image: url(#{$cdn}/image/i/contact/#{$i}.PNG);
				}
			}
		}
		.rcol {
			height: 700px;
		}
	}
	.proezd {
		display: flex;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
		}
		.rcol {
			background: url(/image/enter.jpg) center center no-repeat;
			background-size: cover;
			height: 430px;
		}
		h2 {
			font-size: 36px;
			text-align: left;
			margin-top: 40px;
			margin-bottom: 80px;
			font-weight: 600;
		}
		.ped, .avto {
			@include size(170px, 170px);
			background: $wh top 30px center no-repeat;
			background-size: 45px;
			text-align: center;
			display: inline-block;
			padding: 100px 10px 0 10px;
			font-size: 12px;
			vertical-align: bottom;
		}
		.ped {
			margin-left: 65px;
			margin-right: 30px;
			background-image: url(#{$cdn}/image/i/contact/ped.PNG)
		}
		.avto {
			background-image: url(#{$cdn}/image/i/contact/avto.PNG)
		}
	}
	.proezd2 {
		position: relative;
		padding-bottom: 75px;
		&:after {
			content: '';
			position: absolute;
			background: $wh;
			height: 1px;
			width: 85%;
			bottom: 0;
			left: 65px;
		}
		h5 {
			padding: 60px 0 35px 0;
			font-weight: 600;
		}
		p, h5 {
			font-size: 18px;
		}

	}
	form.content-contact {
		h2 {
			font-size: 36px;
			text-align: left;
			margin: 40px 0;
			font-weight: 600;
		}
		.button1 {
			float: right;
			color: $white;
			font-size: 14px;
			position: absolute;
			bottom: 16px;
			right: 0;
			padding: 6px 52px;
		}
	}
	.form-horizontal .form-group {
		padding: 15px;
		margin: 0;
	}
	@media (max-width: 1199px) {
		.proezd {
			.ped {
				margin-left: 30px;
			}
		}
	}
	@media (max-width: 991px) {
		.proezd {
			.lcol div {
				margin: 0;
			}
		}
	}
	@media (max-width: 767px) {
		.contact-info > div {
			flex-wrap: wrap;
			#map {
				height: 400px;
			}
		}
		.lcol, .rcol {
			width: 100%;
		}
		.proezd {
			h2 {
				margin-bottom: 40px;
			}
			.lcol > div {
				width: 100%;
				height: auto;
				min-height: 50px;
				background-position: center left 5px;
				margin-bottom: 10px;
				text-align: left;
			}
			.ped {
				padding: 20px 10px 20px 60px;

			}
			.avto {
				padding: 10px 10px 10px 60px;
			}
		}
		.proezd2 {
			padding-bottom: 40px;
			h5 {
				padding: 30px 0 15px 0;
			}
		}
		form.content-contact .button1 {
			position: static;
		}
	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}
}

.product-block {
	.name {
		a {
			color: $mg;
			&:hover {
				color: $rd;
			}
		}
	}
	.price .price-new {
		color: $gr2;
	}
	.bottom {
		@include flex();
		z-index: 999;
		margin: 0 30px;
		padding: 0;
		text-align: center;
		.cart {
			flex: 1;
			padding: 0;
			.btn {
				width: 100%;
				@include button-variant-outline($wh, $rd, $rd, $wh, $ml, $ml);
			}
		}
		.action {
			margin-left: 15px;
			background: transparent;
			border: none;
			width: 40px;
			height: 40px;
			padding: 0;
			svg {
				@include fade();
				fill: $rd3;
				opacity: .5;
				width: 40px;
				height: 40px;
			}
			&:hover {
				svg {
					opacity: 1;
				}
			}
		}
	}
}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $product-filter-color;
	text-transform: uppercase;
	font-size: $product-filter-font-size;
	font-weight: 400;
	margin: 15px;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-left: 5px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 24px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color;
			@include box-shadow (none);
		}
	}
}

#products {
	.products-block {
		@include flex();
		flex-wrap: wrap;
		align-items: stretch;
	}
	@media (min-width: 992px) {
		&.product-grid {
			.product-block {
				@include box-shadow(0 0 0 transparent);
				@include transition (all 200ms ease-in-out);
				&:hover {
					@include box-shadow(0 0 20px transparentize($mg, .7));
					transform: scale(1.08);
					z-index: 1500;
				}
			}
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 15px;
		padding-bottom: 15px;
	}
	.product-block {
		@include flex();
		border-top: 1px solid $bc;
		border-radius: 0;
		&:last-child {
			border-bottom: 1px solid $bc;
		}
		.image {
			position: relative;
		}
		.name {
			font-weight: 600;
			font-size: 18px;
		}
		.block-img {
			width: 30%;
		}
		.bottom {
			margin: 0;
			justify-content: flex-start;
			.action {
				display: inline-block;
				margin-left: 15px;
			}
			.cart {
				flex: 0;
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
		.product-block {
			width: calc(100% / 4 - 33px);
		}
	}
	.product-block {
		display: inline-block;
		width: calc(100% / 4);
		border: 1px solid $bc;
		background: $wh;
		@include rounded-corners(0);
		@include transition(transform 0.2s ease-in-out);
		.description {
			display: none;
		}
		.name {
			font-size: 16px;
			font-weight: 300;
			margin: 0 30px 50px;
			text-align: center;
		}
		.product-meta {
			margin-bottom: 50px;
		}
		.image {
			position: relative;
		}
		.price {
			text-align: center;
			line-height: 1;
			margin-bottom: 30px;
			@include flex();
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-end;
			&-new {
				font-weight: 700;
				font-size: 40px;
				display: inline-block;
				.cur {
					font-weight: 300;
				}
				&.hiddenprice {
					font-size: 22px;
				}
			}
			&-old {
				font-size: 16px;
				display: block;
				text-align: left;
				opacity: .5;
			}
		}
		.image {

		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

.product-info {
	.product-info-bg {
		@include flex();
		align-items: stretch;
		flex-wrap: wrap;
	}
	.box-product-infomation {
		margin-top: 20px;
	}
	.image-container {
		padding: 0 0 30px 0;
		border-right: 2px solid $bc;
	}
	.title-product {
		font-size: 30px;
		margin: 0;
		font-weight: 300;
		text-align: center;
		border-bottom: 2px solid $bc;
		padding: 17px 0;
		width: 100%;
		b {
			font-weight: 900;
		}
	}
	.product-view {
		padding: 0 0 30px 0;
	}
	.row {
		padding: 0 15px;
		.list-unstyled {
			@include flex();
			width: 500px;
			justify-content: flex-start;
			margin-bottom: 30px;
			padding: 0 15px;
			&.attrow {
				display: block;
			}
			&.pricerow {
				.price {
					flex: 0 0 250px;
				}
			}
			li {
				flex: 0 0 auto;
			}
		}
		hr {
			border-top: 2px solid $bc;
		}
	}
	.pricerow {
		align-items: flex-end;
		.price {
			font-weight: 300;
			&-old {
				font-size: 16px;
				position: relative;
				top: 7px;
				color: $bk;
			}
			&-new {
				color: $gr2;
				font-size: 48px;
				font-weight: 700;
				display: block;
				line-height: 1;
				&.hiddenprice {
					font-size: 26px;
					margin: 10px 0;
				}
				.cur {
					font-size: 42px;
					font-weight: 300;
				}
			}
		}
	}
	.btn-compare {
		padding: 0;
		height: 40px;
		width: 40px;
		margin-bottom: 8px;
		margin-left: 20px;
		&:hover {
			color: $tc;
			svg {
				opacity: 1;
			}
		}
		svg {
			@include fade();
			fill: $bk;
			opacity: .5;
		}
	}
	.buttonrow {
		align-content: center;
		li {
			&:first-child {
				flex: 0 0 250px;
			}
			.btn {
				width: 200px;
			}
		}
	}
	.cheaprow {
		margin-bottom: 20px;
	}
	.attrow {
		margin-bottom: 15px;
		margin-top: 30px;
		li {
			font-size: 24px;
			line-height: 32px;
			font-weight: 300;
			color: $mg;
			b {
				font-weight: 700;
			}
		}
	}
	.image-additional {
		margin: 0 50px;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 3px solid $wh;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a {
			display: inline-block;
			border: $wh 3px solid;
			&.active {
				border-color: $tc;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;
		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: none;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		margin-bottom: 2px;
		@include rounded-corners(0);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(25px, 39px);
			color: $tc;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 50px;
			line-height: 33px;
			text-align: center;
			font-weight: 300;
			&.add-up {
				font-size: 36px;
				line-height: 41px;
			}
			&:hover, &:focus {
				color: $tc;
			}
		}
		.quantity-number {
			margin: 0 7px;
			input {
				@include size(75px, 40px);
				text-align: center;
				display: inline-block;
				@include rounded-corners(20px);
				box-shadow: none;
				padding: 0;
				border: 2px solid $bc;
				font-size: 28px;
				line-height: 1;
				font-style: normal;
				color: $mg;
				font-weight: 300;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
			margin-left: 16px;
			font-weight: 500;
		}
		.cart {
			padding-bottom: 0;
		}
	}
	.advantages_block {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 10px;
		.advantage_item {
			width: 50%;
			min-width: 262px;
			display: flex;
			align-items: center;
			div {
				@include size(70px, 70px);
				margin: 10px;
			}
			span {
				width: calc(100% - 100px);
				margin: 10px;
			}
		}
		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}
}

.quick-view {
	#content {
		padding: 0 15px !important;
	}
	#sidebar-main {
		margin-bottom: 0;
	}
	.product-info .row {
		.list-unstyled {
			justify-content: left;
			width: auto;
			li {
				align-self: center;
			}
		}
		.pricerow {
			margin: 15px 0;
		}
		#button-cart {
			padding: 0;
			width: 170px;
		}
		#button-cart-credit {
			margin-left: 23px;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	font-size: 26px;
	background: $gbg;
	h1 {
		margin: 0 auto;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 12px 15px 42px 15px;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}
