.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-family: $product-price-font-family;
	font-weight: bold;
	.price-new {
		color: $black;
		font-size: 24px;
	}
	.price-old {
		text-decoration: line-through;
		color: #666;
	}
	&.detail {
		.price-new {
			font-size: 40px;
		}
		.price-old {
			font-size: 26px;
		}
		ul {
			margin: 0;
		}
	}
}

#button-cart-credit {
	margin-right: 30px;
}

.cheap_invoice a, #button-cart-credit, #button-cart-installment {
	font-size: 18px;
	display: block;
	color: $bk;
	cursor: pointer;
	text-decoration: underline;
	&:hover {
		color: $bk;
		text-decoration: none;
	}
}

.category-description {
	padding: 15px;
	h2 {
		font-size: 22px;
		padding: 15px;
		margin: 10px 0;
	}
}

.widget-products .owl-dots {
	margin-top: 15px;
}

.no_scroll_products.product-grid {
	.products-row {
		display: flex;
		flex-wrap: wrap;
		.product-block {
			width: 25%;
		}
	}
}

.product-label {
	font-size: 16px;
	font-weight: 900;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	line-height: 17px;
	padding: 5px 15px;
	&.warranty {
		left: 10px;
		bottom: 10px;
		background: $g;
		color: $wh;
		border-radius: 3px;
		padding: 5px 10px;
	}
	&.sale {
		top: 10px;
		left: 10px;
		color: $special;
	}
	&.new {
		top: 10px;
		left: 10px;
		color: $new;
	}
	&.sale + &.new {
		top: 30px;
	}
	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info .tab-content {
	border: none;
	padding: 0;
	.desc-header-wrapper {
		background: $bc;
		text-align: center;
		position: relative;
		.desc-header {
			padding: 10px 0;
			color: $bk;
			font-size: 34px;
			font-weight: 900;
			position: relative;
			display: inline-block;
		}
	}
	#tab-description {
		padding: 30px 15px;
		font-size: 18px;
		list-style: none !important;
		div[class^='col-'] {
			float: none;
			width: 100%;
		}
	}
	.for-send-error-press {
		font-size: 16px;
		font-style: italic;
		margin: 10px 0;
	}
	.attribs {
		padding: 30px 15px;
		.arow {
			@include flex();
			border-bottom: 1px solid $bc;
			&:last-child {
				border-bottom: none;
			}
			div {
				padding: 15px 0;
				font-size: 18px;
			}
			.asingle {
				text-align: left;
				flex: 1;
				padding: 15px 40px;
			}
			.acol {
				flex: 0 0 50%;
				padding-left: 40px;
				&:first-child {
					text-align: left;
					border-right: 1px solid $bc;
				}
				&:last-child {
					font-weight: 700;
				}
			}
		}
	}
	#tab-downloads {
		ul {
			margin: 0;
			li {
				@include flex();
				justify-content: center;
				padding: 15px;
				font-size: 18px;
			}
		}
	}
	#tab-videos {
		.videos {
			padding: 15px 40px;
		}
	}

	#tab-gifts {
		h3 {
			margin: 0 0 25px 0;
		}
		ul {
			padding: 40px 0 0;
			list-style: none;
			width: 50%;
			margin: 0 auto;
			li {
				&:after {
					content: '\0418\041B\0418';
					display: block;
					text-align: center;
					font-size: 18px;
					color: $black;
					margin: 15px 0;
				}
				&:last-child:after {
					content: none;
				}
			}
			a {
				display: flex;
				align-items: center;
			}
			div {
				display: inline-block;
				img {
					width: 120px;
				}
				&.gift_name {
					font-size: 22px;
					color: $black;
					margin-left: 25px;
					&:hover {
						color: $theme-color;
					}
				}
			}
		}
		p {
			margin: 20px 0;
			color: $black;
			text-align: center;
		}
		@media(max-width: 520px) {
			ul {
				li:after {
					text-align: center;
				}
				a {
					flex-wrap: wrap;
				}
			}
			div {
				width: 100%;
			}
			.gift_name {
				text-align: center;
			}
			.gift_image {
				text-align: center;
				margin-bottom: 20px;
			}
		}
	}
}

.storage_widget {
	padding: 15px 15px 0;
	border-top: 2px $bc solid !important;
	color: $mg;
	line-height: 16px;
	b {
		font-weight: 400;
	}
	& > span:nth-child(2) {
		color: $theme-color !important;
		@include fade();
	}
	table tr {
		td {
			padding-left: 0 !important;
			&:first-child {
				display: none;
			}
			&:last-child span {
				background: $wh !important;
				border-radius: 12px !important;
				text-align: left !important;
				padding: 6px 10px 6px 10px !important;
				color: $tc !important;
				border: 1px $tc solid;
				@include fade();
				&:hover {
					background: $tc !important;
					color: $wh !important;
				}
			}
		}
	}
	> div {
		font-size: 14px !important;
	}
}

#tab-review {
	padding: 20px 15px;
	.review_stars {
		display: inline-block;
		margin-left: 15px;
		.fa-star, .fa-star-o {
			margin: 0 2px;
			color: $rating;
		}
	}
	#form-review-container {
		overflow: hidden;
		& > form {
			margin-top: 15px;
		}
	}
	.recaptcha_container .col-sm-2 {
		display: none;
	}
	.review_h, .review_user {
		font-weight: 600;
	}
	table td {
		padding: 10px 15px
	}
}

.prod_rating {
	margin: 0 15px 20px;
	font-size: 16px;
	.custom-color {
		cursor: pointer;
	}
	.stars {
		display: inline-block;
		margin-right: 10px;
	}
	.fa {
		color: $rating;
	}
}

.product-block {
	.rating {
		text-align: center;
		position: relative;
		top: 15px;
		font-size: 14px;
		color: $rating;
	}
}

.product-list .product-block .rating {
	text-align: left;
}
