/*Text Colors
------------------------------------*/
.text-default {
	color: $brand-default !important;
}

.text-primary {
	color: $brand-primary !important;
}

.text-info {
	color: $brand-info !important;
}

.text-danger {
	color: $brand-danger !important;
}

.text-warning {
	color: $brand-warning !important;
}

.text-success {
	color: $brand-success !important;
}

/*Background Colors
------------------------------------*/
.bg-primary {
	background-color: $brand-primary !important;
}

.bg-info {
	background: $brand-info !important;
}

.bg-danger {
	background: $brand-danger !important;
}

.bg-warning {
	background: $brand-warning !important;
}

.bg-success {
	background: $brand-success !important;
}

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 700;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bo-accordion-color;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $bo-accordion-background;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}

.breadcrumbs {
	padding: 15px 15px;
	min-height: 50px;
	margin-bottom: 0;
	list-style: none;
	background-color: transparent;
	border-radius: $border-radius-base;
	> .container {
		text-align: center;
	}
	.breadcrumb {
		margin: 0;
		padding: 0 2px;
		display: inline-block;
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
	.breadcrumb-links {
		margin: 0;
		position: relative;
		padding: 0 0 10px;
		border-bottom: 1px solid $border-color-base;
		> li {
			display: inline-block;
			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-base - 2;
			}
			+ li:before {
				color: #757575;
				content: "\f105";
				font-family: FontAwesome;
				padding: 0 15px;
				font-size: 14px;
			}
			&:last-child {
				a {
					color: $black;
				}
			}
		}
		> .active {
			color: $link-hover-color;
		}
	}
}

.breadcrumbs-left {
	h2 {
		float: none;
		text-align: left;
	}
	.breadcrumb-links {
		text-align: left;
	}
}

.heading {
	z-index: 1;
	position: relative;
	text-align: center;
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
	}
	p, small {
		margin-bottom: 15px;
		display: inline-block;
	}
	&.heading-light {
		color: #eee;
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
		p, small {
			color: #eee;
		}
	}
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
}

@mixin translateY($y) {
	-webkit-transform: translateY($y);
	-ms-transform: translateY($y); // IE9 only
	-o-transform: translateY($y);
	transform: translateY($y);
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #e4e3e3;
	padding: 50px;
	left: 50%;
	top: 22%;
	font-size: 16px;
	color: #333333 !important;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 15000;
	position: fixed;
	a {
		text-decoration: none !important;
		font-size: 16px;
		&:hover {
			text-decoration: underline;
		}
	}
	.close {
		opacity: 1;
		float: right;
		position: absolute;
		top: 20px;
		right: 20px;
		@include size(20px, 20px);
		cursor: pointer;
	}
	.success_button {
		@include transition(0.2s all);
		background: $rd;
		border: none;
		display: inline-block;
		cursor: pointer;
		color: white !important;
		font-size: 18px;
		font-weight: 300;
		padding: 6px 24px;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			text-decoration: none !important;
			background: $rd2;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}

/* CallBack in header */
.callback_push {
	position: fixed;
	top: 30%;
	right: 30px;
	z-index: 100;
	a {
		width: 70px;
		height: 70px;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		border-radius: 50%;
		z-index: 9999;
		transition: all .5s;
		overflow: visible;
		svg {
			overflow: visible;
			width: 100%;
			path {
				fill: $rd;
				stroke: none;
			}
			circle {
				stroke: $rd;
				fill: none;
				stroke-width: 2px;
				&.anim {
					opacity: 0;
					transform-origin: center;
					animation-duration: .5s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;

				}
			}
			&:hover circle.anim {
				animation-name: videoplay;
			}
		}
	}
	span.callback_tooltip {
		position: absolute;
		top: 25px;
		left: -190px;
		display: block;
		width: 150px;
		background: $rd;
		color: #fff;
		line-height: 1;
		border-radius: 5px;
		padding: 5px 10px;
		text-align: center;
		transition: all .5s;
		opacity: 0;
		visibility: hidden;
		&:after {
			content: '';
			position: absolute;
			top: 5px;
			right: -13px;
			border: 7px solid transparent;
			border-left-color: $rd;
		}
	}
	&:hover span.callback_tooltip {
		opacity: 1;
		visibility: visible;
		left: -160px;
	}
	&_hidden {
		-webkit-transition: all 150ms ease-out;
		-o-transition: all 150ms ease-out;
		transition: all 150ms ease-out;
		opacity: 0;
	}
}

.form_callback_form .callback_button, #reserve_form .reserve_button {
	background: $tc !important;
	background-image: -webkit-linear-gradient(top, $tc, $tc) !important;
	background-image: -moz-linear-gradient(top, $tc, $tc) !important;
	background-image: -ms-linear-gradient(top, $tc, $tc) !important;
	background-image: -o-linear-gradient(top, $tc, $tc) !important;
	background-image: linear-gradient(to bottom, $tc, $tc) !important;
	color: #ffffff;
}

@keyframes videoplay {
	0% {
		transform: scale(1);
		opacity: 0;
	}
	50% {
		transform: scale(1.15);
		opacity: .7;
	}
	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}