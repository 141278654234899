.breadcrumb {
	> li {
		display: inline-block;
		font-family: $f2;
		font-size: 16px;
		line-height: 20px;
		font-weight: 300;
		+ li:before {
			content: "|\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 0 0 4px;
			color: $mg;
		}
		a {
			color: $mg;
		}
	}

	> .active {
		font-weight: 700;
	}
}
