html {
	overflow-x: hidden;
	&.qwpage {
		overflow: hidden;
		.advantages_block {
			display: none;
		}
	}
}

body {
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}
		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
	}
	&[class*="information-"] img {
		max-width: 100%;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
	}
}

.main-columns {
	padding: 0 30px;
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

#sidebar-main {
	background: $wh;
	padding: 0;
	margin-bottom: 50px;
}

.common-home {
	#sidebar-main {
		background: transparent;
	}
	#content {
		padding: 0 15px !important;
	}
}

.fancybox-skin {
	background: $wh;
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.header-top {
	height: 100px;
	display: flex;
	& > .container {
		display: flex;
		justify-content: space-between;
		&:before, &:after {
			content: none;
		}
	}
	background-color: $bc;
	color: $black;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	.btn-group {
		margin-right: 20px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
		&:hover {

		}
	}
	.logo-container {
		flex: 1 1 auto;
		text-align: center;
	}
	.topcontacts {
		flex: 0 0 255px;
		align-self: center;
		display: flex;
		svg {
			fill: $ml;
			width: 35px;
    		height: 48px;
			margin-right: 10px;
			@media (min-width: 638px) and (max-width: 767px) {
				width: 20px;
				margin-right: 5px;
			}
		}
		.media-body {
			width: auto;
			display: flex;
			color: $gr;
			font-size: 18px;
    		font-weight: 800;
			line-height: normal;
			a {
				color: $gr !important;
			}
		}
		&.phone {
			justify-content: flex-end;
			.media-body {
				text-align: right;
			}
		}
		&.worktime {
			
		}
	}
}

.bigcat .col-lg-6 {
	padding: 0 !important;
}

.header-bottom {
	background: transparent url('#{$image-theme-path-base}top_bg.jpg') no-repeat bottom center;
	background-size: cover;
	height: 100px;
	display: flex;
	position: relative;
	&:after {

	}
	& > .container {
		display: flex;
	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
	#searchblock {
		visibility: hidden;
		position: absolute;
		width: 220px;
		@include transition(transform 0.2s ease-in-out);
		transition: all 0.2s ease-in-out;
		transform: scale(0, 1);
		z-index: 999;
		right: -13px;
		&.active {
			visibility: visible;
			transform: scale(1, 1);
		}
	}
	#searchtop {
		display: inline-block;
		@include rounded-corners(20px);
		background: $wh;
		width: 100%;
		input.form-control {
			background: transparent;
			border-radius: 0;
			border: none;
			color: $bk;
			@include placeholder($lg);
		}
		.btn-search {
			display: block;
			background: transparent;
			font-size: 18px !important;
			cursor: pointer;
			color: $lg;
			position: absolute;
			right: 0;
			top: 3px;
			border: none;
			@include fade();
			&:hover {
				color: $bk;
			}
		}
	}
	.header-panel {
		@include flex();
		justify-content: right;
		align-items: center;
		position: relative;
		svg {
			fill: $wh;
			width: 30px;
			height: 30px;
		}
	}
	.headercart {
		width: 30px;
		position: relative;
		.cart-total {
			top: auto;
			right: auto;
			left: 50%;
			bottom: -17px;
			transform: translateX(-50%);
		}
		#cart {
			&.open .dropdown-toggle {
				box-shadow: none;
			}
			a {
				display: inline-block;
				position: relative;
			}
			.icon-cart {
				background: transparent;
				@include size(55px, 47px);
				display: inline-block;
				margin-right: 10px;
				position: relative;
				text-align: center;
				i {
					font-size: 30px;
					padding: 8px;
				}
			}
			.wrap-cart {
				display: inline-block;
				color: $black;
				.cart-total {
					font-weight: bold;
				}
			}
		}
	}
	.headersearch {
		cursor: pointer;
		svg {
			width: 30px;
		}
	}
	.product-compare-header {
		text-align: center;
		width: 30px;
		position: relative;
		&:hover {
			svg {
				fill: $bc;
			}
		}
		p {
			margin: 0;
			&#compare-total {
				font-weight: bold;
				line-height: 14px;
				font-size: 12px;
			}
		}
		i {
			font-size: 28px;
		}
	}
	.badge {
		color: $bk;
		position: absolute !important;
		top: -6px;
		right: -4px;
		padding: 3px;
		min-width: 17px;
		line-height: 11px;
		z-index: 99;
		background: $lg;
	}
	.product-compare-header, .headercart, .headersearch {
		margin: 0 18px;
	}
}

#partnerpricemodule {
	background: $wh;
	text-align: center;
	padding: 40px 30px;
	color: $wh;
	background: $tc;
	h3 {
		color: $wh;
		text-transform: uppercase;
		font-size: 30px;
	}
	p {
		margin-bottom: 15px;
	}
	button {
		padding: 0 30px;
		color: $tc;
		background: $wh;
		font-weight: 700;
		font-size: 22px;
		border: 1px solid $wh;
		&:hover {
			border: 1px solid $wh;
			color: $wh;
			background: $tc;
		}
	}
}

.search_ctrl {
	padding: 0 15px;
}

.compare_rm_all_top {
	position: absolute;
	right: 15px;
	top: 15px;
}

.compare_rm_all_bottom {
	margin: -15px 15px 15px -15px;
	text-align: right;
	display: inline-block;
	width: 100%;
	@media (max-width: 767px) {
		margin-top: 15px;
	}
}

.shopping-cart-table {
	background: $wh;
	font-size: 16px;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			a:hover {
				color: $special;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				color: $special;
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid #ddd;
	}
	&.table {
		thead tr td {
			text-align: center !important;
		}
		tr td {
			border: 1px #ddd solid;
			&.column-price, &.column-total {
				white-space: nowrap;
			}
		}
	}
	.column-quantity {
		min-width: 75px;
		.btn-block {
			min-width: 100px;
			margin: 0 auto;
			text-align: center;
			span {
				display: inline-block;
			}
		}
		input {
			margin-bottom: 10px;
			color: $mg;
			font-style: normal;
			font-size: 22px;
			border-radius: 18px;
			text-align: center;
		}
		svg {
			width: 30px;
			height: 30px;
			cursor: pointer;
			opacity: .5;
			@include fade();
			&:hover {
				opacity: 1;
			}
		}
		.cart-remove {
			svg {
				fill: $mg;
			}
		}
		.cart-update {
			svg {
				fill: $tc;
			}
		}
	}
}

table.total-item {
	font-size: 18px;
	float: right;
	margin-top: 10px;
	background: white;
	tr td:last-child {
		width: 125px;
	}
}

.total-item, .shopping-cart-table {
	tr td {
		vertical-align: middle !important;
		padding: 10px !important;
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	@include flex();
	align-items: center;
	flex: 1 1 auto;

	a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		text-align: center;
		position: relative;

		img {
			max-width: 256px;
		}
		span {
			text-transform: initial;
		}
	}
	svg {
		width: 100%;
		max-height: 65px;
		fill: $bk;
		text {
			text-align: center;
			font-size: 7.5em;
			letter-spacing: .09em;
			fill: $bk;
			font-family: $f;
			text-transform: uppercase;
		}
	}
	img {
		display: inline-block;
	}

}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 30px;
			&.ft-mail {
				a {
					text-transform: lowercase;
				}
			}
		}
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: $wh;
	padding: 40px 0;
	color: $mg;
	font-size: 16px;
	a {
		color: $mg;
		&:hover {
			color: $rd;
		}
	}
	.box {
		.box-heading {
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 5px;
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
	}
	.main-footer {
		display: flex;
		justify-content: space-between;
		margin-left: 100px;
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $rd2;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		width: 100%;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $white;
		font-size: 13px;
		font-weight: 400;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.oferta p {
			color: $wh;
			font-size: 13px;
		}
	}
	.payment-img {
		text-align: right;

		img {
			max-height: 61px;
		}
	}
}
.payment2row {
	display: none;
	margin: 8px auto;
}