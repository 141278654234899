@font-face {
	font-family: 'Akrobat';
	src: url('/fonts/akrobat/subset-Akrobat-Regular.eot');
	src: url('/fonts/akrobat/subset-Akrobat-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/akrobat/subset-Akrobat-Regular.woff2') format('woff2'),
	url('/fonts/akrobat/subset-Akrobat-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima';
	src: url('/fonts/proxima/subset-ProximaNova-Light.eot');
	src: url('/fonts/proxima/subset-ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
	url('/fonts/proxima/subset-ProximaNova-Light.woff2') format('woff2'),
	url('/fonts/proxima/subset-ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima';
	src: url('/fonts/proxima/subset-ProximaNova-Regular.eot');
	src: url('/fonts/proxima/subset-ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/proxima/subset-ProximaNova-Regular.woff2') format('woff2'),
	url('/fonts/proxima/subset-ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima';
	src: url('/fonts/proxima/subset-ProximaNova-Bold.eot');
	src: url('/fonts/proxima/subset-ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/proxima/subset-ProximaNova-Bold.woff2') format('woff2'),
	url('/fonts/proxima/subset-ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima';
	src: url('/fonts/proxima/subset-ProximaNova-Black.eot');
	src: url('/fonts/proxima/subset-ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
	url('/fonts/proxima/subset-ProximaNova-Black.woff2') format('woff2'),
	url('/fonts/proxima/subset-ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
