@media (max-width: $screen-md-max) {
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 38px 14px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.header-top .topcontacts.phone {
		flex: 0 1 230px;
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 170px;
		}
		.nav-search {
			margin-bottom: 7px;
		}
	}
	.product-grid .product-block .bottom .action, .products-owl-carousel .product-block .bottom .action {
		margin-left: 5px;
	}
	.category_widget .type1 .img h3 {
		font-size: 18px;
	}
	.category_widget .type2 .img {
		height: 300px;
	}
	.category_widget .type2 .img h3 {
		padding: 20px;
		font-size: 28px;
	}
	.s24 {
		width: 120px;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 3);
			.action > div {
				padding-right: 2px;
			}
			.product-meta {
				margin-bottom: 25px;
				.name {
					margin: 15px;
				}
				.price {
					padding: 15px 0;
					margin: 0;
				}
			}
		}
	}
	.header-top {
		height: 75px;
		.topcontacts, .topcontacts.phone {
			flex: 0 0 250px;
			padding-left: 0;
			a {
				color: $bk !important;
			}
			.media-title {
				padding: 7px 10px;
			}
			.media-body {
				line-height: unset;
				span {
					vertical-align: baseline;
					font-size: 12px;
					color: $bk !important;
				}
			}
		}
		.topcontacts svg {
			height: 38px;
		}
	}
	.logo-store {
		a {
			span {
				position: absolute;
				bottom: -20px;
			}
		}
		img {
			margin-top: -10px;
		}
	}
	#powered {
		.copyright {
			font-size: 13px;
		}
		.payment-img {
			img {
				&:first-child {
					display: none;
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
	.s24 {
		width: 90px;
	}
	.header-bottom {
		.main-menu {
			.pav-megamenu {
				.btn-primary {
					padding: 0 13px;
					&:hover {
						background: $tc;
					}
					span {
						font-size: 18px;
						vertical-align: text-bottom;
					}
				}
			}
		}
		.nav-search {
		}
		.logo-container {
			flex: 1 1 auto;
			text-align: center;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 15px;
	}
	.h1_container h1 {
		padding: 0 15px 21px 15px;
	}
	.category_widget .type2 .img {
		height: 250px;
	}
	.category_widget .type2 .img h3 {
		padding: 15px;
		font-size: 22px;
	}
	.cat_all {
		padding: 0 0 0 15px !important;
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 0 5px;
				font-size: 18px;
				line-height: 14px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {
				i:before {
					content: '\f105'
				}
				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 25px);
					display: inline-block;
				}
				&.opened {
					ul {
						display: block;
					}
					i:before {
						content: '\f107'
					}
				}
			}
			ul.children {
				li {
					padding: 0;
				}
			}
		}
	}

	.category_widget {
		.type1 {
			h3 {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.product-info {
		.row {
			.list-unstyled {
				flex-wrap: wrap;
				width: auto;
				justify-content: flex-start;
				margin: 15px 0 0 0;
				&.attrow li {
					font-size: 20px;
					margin-bottom: 0;
				}
				&.cheaprow {
					li {
						width: 100%;
					}
				}
				&.pricerow {
					margin-bottom: 30px;
					.price {
						margin-bottom: 20px;
					}
				}
				&.buttonrow {
					.btn {
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		font-size: 14px;
	}

	.product-markdown-view {
		.cheaper-products {
			&.general {
				&.markdown-product {
					.cheaper-items {
						.cheaper-item {
							flex-wrap: nowrap;
						}
					}
				}
				.cheaper-items {
					.cheaper-item {
						&__price {
							flex-direction: row;
							align-items: flex-end;

							.price-new {
								font-size: 32px;
							}
							.price-old {
								font-size: 18px;
								line-height: 18px;
							}
						}
					}
				}

				.buttonrow, .cheaprow {
					flex-direction: column;
				}

				.container-btns {
					.btn-primary, .btn-default, .button-credit {
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	#header-layout {
		.header-top {
			.container {
				width: 100%;
			}
		}
	}
	.category_widget .type1 .img {
		margin-bottom: 15px;
		h3 {
			height: auto;
		}
	}
	.s24 {
		width: 20vw;
	}
	.category_widget .type2 .img h3 {
		white-space: normal;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2);
		}
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	#powered .inner {
		justify-content: center;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
			padding-left: 5px;
		}
		tr {
			display: block;
			td {
				position: relative;
				padding-left: 43% !important;
				text-align: left !important;
				white-space: normal !important;
				&:first-child {
					border-top: 0;
				}
				div.th-title {
					display: block;
					position: absolute;
					left: 10px;
					width: 38%;
					padding-right: 10px;
					text-align: right;
					top: 50%;
					@include transform(translateY(-50%));
					font-weight: bold;
					text-transform: uppercase;
				}
				&.no-title {
					padding-left: 20px !important;
					text-align: center !important;
				}
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-top {
		.headercart {
			flex: 1 1 200px;
		}
	}
	.header-bottom {
		height: 70px;
		> .container {
			flex: 1;
		}
		.header-panel {
			flex: 0 1 200px;
			& > div {
				margin-bottom: -7px !important;
			}
		}
		.main-menu {
			flex: 1;
		}
		.headercart .cart-total {
			display: none;
		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
		}
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.producttabs .tab-header {
		font-size: calc(4vw + 14px);
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			font-size: 26px;
		}
		#tab-description, #tab-downloads {
			font-size: 14px;
			ul {
				padding-left: 10px;
			}
		}
		.attribs {
			.arow {
				div {
					font-size: 14px;
					padding: 5px 10px;
				}
				.asingle {
					padding: 5px 10px;
				}
				.acol {
					padding-left: 10px;
				}
			}
		}
	}
	.product-info .image-container {
		padding-bottom: 0;
	}
	.category_widget .type2 .img {
		height: 55vw;
	}
	.fancy_holiday_works {
		width: 90% !important;
		.fancybox-inner {
			width: 100% !important;
		}
	}
	.holiday_table {
		width: 100%;
		td {
			font-size: 13px;
		}
	}
	.fancybox-skin {
		padding: 10px !important;
	}
	.product-markdown-view {
		.cheaper-products {
			.btn-compare {
				display: none;
			}
		}
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.header-top .topcontacts.worktime {
		display: none;
	}
}

@media (max-width: 570px) {
	.owl-dots {
		margin-top: 17px;
	}
	#powered > .container {
		padding: 0;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 155px;
	}
	.header-bottom .nav-search {
		flex: 0 1 30px;
		margin-right: 15px;
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.catwidget_header h2 {
		font-size: 40px;
		margin-top: 30px;
		margin-bottom: 15px;
	}
}

@media (max-width: 510px) {
	.product-info .row .list-unstyled.pricerow .price {
		flex: 0 0 100%;
	}
}

@media (max-width: $screen-xs) {
	.category_widget .type2 .img h3 {
		font-size: 24px;
	}
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.footer-center {
		font-size: 14px;
	}
	.product-info .row .list-unstyled li.price {
		flex: 1 1 100%;
	}
	.product-grid {
		.product-block {
			width: 100%;
			.product-meta .name {
				margin: 15px 15px 0 15px;
			}
		}
	}
	#partnerpricemodule {
		padding: 30px 15px;
		h3 {
			font-size: 20px;
		}
		button {
			padding: 0 12px;
			font-size: 18px;
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
}