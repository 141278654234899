body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

.owl-related {
	padding: 0 !important;
	.owl-stage-outer {
		padding: 0 !important;
	}
	.owl-dots .owl-dot {
		background: $bc;
	}
}

.desc-header-related {
	.scroll-button {
		position: absolute;
		right: 0;
		top: 18px;
	}
	@media (max-width: 767px) {
		display: block;
		.scroll-button {
			position: relative;
			margin-bottom: 15px;
		}
	}
}