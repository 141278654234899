$f: 'Proxima';
$f2: 'Akrobat';
$fa: 'FontAwesome';

$tc_old: #009bde !default;
$tc: #a31d2c !default;
$tc2: #00477e !default;
$bk_old: #00477f !default;
$bk: #990012 !default;
$wh: #fff !default;
$ml: #d10019 !default;
$rd: #990012 !default;
$rd2: #a31d2c !default;
$rd3: #d12c40 !default;

$mg: #333333 !default;
$gr: #7c7c7c !default;
$gr2: #5e5e5e !default;
$lg: #bababa !default;
$bc: #f3f3f3 !default;
$gb: #8094a5 !default;
$g:  #008f10 !default;

$new: #005984 !default;
$special: #F44336 !default;
$rating: #ffbb00 !default;

$headings-color: #990012 !default;

$cdn: 'https://cdn.hawkshop.ru';

@mixin fade() {
	@include transition(all 0.2s ease-in-out);
}

@mixin flex() {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}