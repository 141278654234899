button {
	&:active, &:focus {
		outline: none !important;
	}
}

h1 {
	font-weight: 300;
	text-align: center;
	margin: 0;
}

#page {
	min-height: 100vh;
	background: $bc;
	& > .container {
		min-height: calc(100vh - 560px);
	}
}

.fade {
	opacity: 0;
	@include transition(opacity .15s linear);
	&.in {
		opacity: 1;
	}
}

#is_ip .radio_custom .input-control {
	margin-right: 15px;
}

.collapse {
	display: none;
	visibility: hidden;

	&.in {
		display: block;
		visibility: visible;
	}
}

tr.collapse.in {
	display: table-row;
}

tbody.collapse.in {
	display: table-row-group;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	@include transition-property(height, visibility);
	@include transition-duration(.35s);
	@include transition-timing-function(ease);
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

.bannercontainer {
	background: transparent url('#{$image-theme-path-base}banbg.jpg') no-repeat center top;
}

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

#button-cart {
	padding: 0 37px;
}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

.btn {
	border: none;
	@include fade();
	@include rounded-corners(20px);
	height: 40px;
	font-size: 18px;
	font-weight: 400;
	line-height: 40px;
	&-primary {
		@include button-variant-outline($wh, $rd, $rd, $wh, $ml, $ml);
	}
	&-default {
		border: 2px solid;
		line-height: 36px;
		@include button-variant-outline($gb, $wh, $gb, $wh, $gb, $gb);
	}
}

#input-topic {
	list-style: none;
	padding: 0;
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.modal-dialog {
	.reserve_button, .callback_button {
		@include rounded-corners(0);
		background: $tc;
		@include transition(0.2s all);
		padding: 10px 15px;
		text-transform: uppercase;
		font-family: $font-family-base;
		&:hover {
			background: $black;
		}
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $tc;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 170px;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: none;
			&:focus, &:active {
				outline: none !important;
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 350px;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: calc(100% - 1px);
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:nth-last-child(-n+2) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 10px 0;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 700;
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 421px) {
			top: calc(100% + 2px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

.producttabs {
	margin: 0;
	padding-bottom: 50px;
	.tab-header {
		text-align: center;
		text-transform: uppercase;
		font-weight: 900;
		color: $wh;
		font-size: 48px;
		height: 265px;
		background: url('#{$image-theme-path-base}tabh.jpg') no-repeat center top;
		background-size: cover;
		span {
			line-height: 1;
			padding-top: 42px;
			display: inline-block;
		}
	}
	.owl-carousel-play {
		margin-top: -140px;
	}
	.owl-stage-outer {
		margin-bottom: 15px;
	}
}

.owl-dots {
	text-align: center;
	.owl-dot {
		display: inline-block;
		background: $wh;
		width: 40px;
		height: 5px;
		margin: 0 5px;
		@include rounded-corners(3px);
		&.active {
			background: $ml;
		}
		span {
			display: inline-block;
		}
	}
}

.tp-bullets.simplebullets.round .bullet{
	&:hover {
		background: $ml;
	}
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 500;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 500;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background-color: $wh;
			border: 2px solid $rd;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.55);
			transform-origin: center center;
			width: 100%;
			background: $rd;
			@include opacity(0);
			@include transition(.1s opacity linear);
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

#direktoru_link {
	margin-top: 15px;
	position: relative;
	a {
		font-size: 18px;
		text-decoration: underline;
	}
}

.direktoru {
	.radio_custom {
		cursor: default;
		.input-control__indicator {
			top: 13px;
		}
	}
}

.checkbox_custom {
	a {
		padding-left: 10px;
		font-size: 14px;
		color: $tc;
	}
	.agree b {
		font-weight: 500;
		padding: 0;
		font-size: 12px;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		margin: 5px 10px 5px 14px;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(16px, 16px);
			text-align: center;
			background-color: $white;
			border: 2px $rd solid;
			@include rounded-corners(0px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 4px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $rd 2px solid;
			width: 5px;
			height: 10px;
			border-right: $rd 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

#button-order {
	margin-top: 15px;
}

.catwidget_header h2 {
	text-align: center;
	font-weight: 900;
	font-size: 60px;
	margin-top: 45px;
	margin-bottom: 30px;
}

.category_widget {
	.img {
		position: relative;
		background: transparent center no-repeat;
		background-size: cover;
		h3 {
			position: absolute;
			text-transform: uppercase;
			text-align: center;
			color: $white;
			display: inline-block;
		}
	}
	.type1 {
		padding-bottom: 50px;
		.img {
			height: 230px;
			margin-bottom: 30px;
			h3 {
				margin: 0;
				height: 60px;
				left: 0;
				right: 0;
				bottom: 0;
				background: transparentize($rd, .3);
				font-weight: 700;
				font-size: 24px;
				padding: 20px 0;
			}
		}
		.descr {
			font-size: 16px;
		}
	}
	.type2 {
		.img {
			height: 350px;
			h3 {
				margin: 0;
				padding: 30px;
				background: transparentize($bk, .5);
				font-size: 36px;
				font-weight: 900;
				left: 0;
				bottom: 0;
				right: 0;
				white-space: nowrap;
			}
		}
	}
}

.product-compare {
	.comparetable {
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		.btn {
			width: 150px;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 41px;
	height: 41px;
	background-color: $rd;
	z-index: 999;
	@include rounded-corners(100%);
	@include transition(200ms all ease-in-out);
	i {
		font-size: 26px;
		color: $wh;
		position: absolute;
		left: 12px;
		top: 5px;
	}
	&:hover {
		background-color: $rd2;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#button-coupon {
		padding: 0 16px;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		width: 100%;
	}
}

.product-grid {
	.col-nopadding {
		@include flex();
		flex-wrap: wrap;
		padding: 0;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
	font-size: 16px;
	overflow-x: auto;
}

.owl-prev, .owl-next {
	line-height: 1;
	font-size: 30px;
	position: absolute;
	top: calc(50% - 20px);
	padding: 4px 9px;
	width: 30px;
	height: 40px;
	color: $tc;
	@include transition(0.2s ease all);
	background: transparent;
}

.owl-prev {
	left: -26px;
	background-image: url(#{$cdn}/image/i/arrow1l.png);
}

.owl-next {
	right: -26px;
	padding: 4px 11px;
	background-image: url(#{$cdn}/image/i/arrow1r.png);
}

.mfilter-box {
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			.mfilter-heading-content {
				padding: 20px 0 6px 0;
			}
			.mfilter-head-icon {
				@include size(25px, 20px);
				@include transition(none);
				@include notransform();
				background: url(#{$cdn}/image/i/mfminus.png) no-repeat transparent center;
			}
			&.mfilter-collapsed .mfilter-head-icon {
				background-image: url(#{$cdn}/image/i/mfplus.png);
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin-left: 0;
			> span {
				padding-left: 0;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 1px solid $lg;
			padding: 9px 0 5px 0;
			label {
				font-weight: 400;
			}
			.mfilter-counter {
				border-radius: 0;
				background: #acacac;
				&:after {
					content: none;
				}
			}
			input[type="text"] {
				border-radius: 0;
				height: 45px;
			}
			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
				background: $white;
			}
			.mfilter-slider-slider, #mfilter-price-slider {
				border: $lg 1px solid;
				background: $white;
				margin: 15px 2px 0 5px !important;
				.ui-slider-range {
					background: $lg !important;
				}
				.ui-slider-handle {
					background: $white !important;
					height: 18px !important;
					border: 1px $lg solid !important;
					border-radius: 0;
				}
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $tc;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.widget-products {
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
	.owl-next, .owl-prev {
		top: calc(50% - 40px);
	}
}

#request-on-order-form .btn-buy-product {
	background: $tc !important;
	padding: 0 37px !important;
	color: $white !important;
	@include transition(0.2s all);
	&:hover {
		background: $bk !important;
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
}

.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		@include flex();
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		@include flex();
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

.holiday_works {
	text-align: left;
	border: none !important;
	margin-left: auto !important;
	position: absolute;
	top: 54px;
	width: unset;
	white-space: nowrap;
}

.zoomWindow {
	background-color: $wh;
}

.product-markdown-view {
	.cheaper-products {
		margin: 0 15px 15px 15px;
		padding: 16px;
		border: 1px solid rgba(0, 0, 0, 0.12);

		&.general {
			border: 1px solid $bk;

			.cheaper-items {
				margin-bottom: 15px;

				.cheaper-item {
					gap: 16px;
					flex-direction: row;
					align-items: flex-end;
					flex-wrap: wrap;

					.cheaper-item-info + .btn-compare {
						margin-bottom: 30px;
					}
					&__status {
						font-weight: 600;
					}
					&__price {
						flex-direction: column-reverse;
						align-items: flex-start;

						.price-new {
							font-size: 48px;
						}
						.price-old {
							font-size: 22px;
							line-height: 22px;
							top: unset;
						}
					}
					&:hover {
						cursor: default;
					}

					.btn-compare {
						margin-left: 0;
						margin-bottom: 0;
					}
				}
			}

			&.markdown-product {
				.cheaper-item {
					&__price {
						flex-direction: row;
						align-items: flex-end;
					}
				}
			}

			.buttonrow, .cheaprow {
				margin: 0;
				padding: 0;
				display: flex;
				gap: 15px;
				justify-content: flex-start;
			}
			.container-btns {
				display: flex;
				flex-direction: column;
				gap: 15px;

				#button-cart-credit {
					margin-right: 0;
				}
			}
		}
		&__title {
			font-weight: 600;
			margin-bottom: 5px;
			font-size: 18px;
		}
		.cheaper-items {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;

			.cheaper-item {
				display: flex;
				flex-direction: column;
				gap: 8px;
				width: 100%;
				@include rounded-corners(3px);

				.cheaper-item-info {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					flex-direction: column;
					align-items: flex-start;
					gap: 12px;
				}
				&__price {
					display: flex;
					align-items: flex-end;
					white-space: nowrap;
					justify-content: flex-start;
					gap: 16px;

					.price-new {
						font-size: 24px;
						font-weight: 500;
						line-height: 1;

						.cur {
							&:before {
								font-size: 20px;
							}
						}
					}
					.price-old {
						font-size: 14px;
						@include opacity(0.5);
						text-decoration: line-through;
						line-height: 14px;
						font-weight: 500;

						.cur {
							&:before {
								font-size: 12px;
							}
						}
					}
				}
				&__status {
					font-size: 16px;
					color: $mg;
				}

				&__title {
					span {
						color: $gr;
						font-size: 14px;
						line-height: 17px;
						display: inline-block;
					}
				}

				&:hover {
					cursor: pointer;
					color: $tc !important;
				}
			}
		}
	}
}

@media (max-width: 636px) {
	.holiday_works {
		text-align: right;
		width: calc(100% - 30px);
	}
}
@media (min-width: 992px) {
	.holiday_works {
		top: 75px;
		padding-left: 15px;
	}
}